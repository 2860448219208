module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.zencargo.com/graphql","useACF":true,"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zencargo Virtual Event 2021","short_name":"Zencargo","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#00ADEF","display":"standalone","icon":"src/assets/images/favicon.jpg","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a49e2268127286c4451afe66da0f6848"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
